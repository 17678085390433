import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardHeader,
  Stack,
  Typography,
} from '@mui/material';
import { theme } from '@theme/theme';
import { GenericModal, useModal } from '../GenericModal';

interface Props {
  title: string;
  image?: string;
  description: string;
  url: string;
  actions?: React.ReactNode;
  location?: React.ReactNode;
}

export function ArticleResourceCard({
  title,
  url,
  description,
  image,
  actions,
  location,
}: Props) {
  const { isOpen, closeModal, openModal } = useModal();

  return (
    <Card
      elevation={5}
      sx={{
        height: 1,
        bgcolor: 'secondary.main',
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h6',
          fontWeight: 'bold',
          color: '#050608',
          textAlign: 'left',
        }}
        sx={{ pt: 2, pb: 1, bgcolor: '#FFFFFF' }}
      />
      <ButtonBase onClick={openModal} sx={{ width: 1 }}>
        <Box sx={{ aspectRatio: '6/5', width: 1 }}>
          <Box
            p={1}
            sx={{
              width: 1,
              aspectRatio: '2',
              overflow: 'hidden',
              bgcolor: '#FFFFFF',
            }}
          >
            {image && (
              <Box
                component="img"
                src={image}
                alt={title}
                sx={{
                  width: 1,
                  height: 1,
                  objectFit: 'cover',
                }}
              />
            )}
          </Box>
          <Box
            color="#ffffff"
            p={theme.spacing(1, 2, 2, 2)}
            borderRadius={theme.spacing(0, 0, 2, 2)}
            sx={{ aspectRatio: '6/2' }}
          >
            <Box sx={{ aspectRatio: '6/2', overflow: 'auto' }}>
              <Typography>{description}</Typography>
            </Box>
          </Box>
        </Box>
      </ButtonBase>

      <GenericModal
        isOpen={isOpen}
        onClose={closeModal}
        width={1200}
        headerColor="transparent"
        closeIconBgColor={theme.palette.error.main}
        closeIconColor="#fff"
        title={location}
        useOnlyCloseButton
      >
        <Box width={1} position="relative" sx={{ aspectRatio: '2' }}>
          <Box
            component="img"
            src={image}
            alt={title}
            sx={{
              width: 1,
              height: 1,
              objectFit: 'cover',
            }}
          />
          <Stack
            position="absolute"
            bottom={16}
            right={16}
            direction="row-reverse"
          >
            <Button
              variant="contained"
              color="primary"
              endIcon={<OpenInNew />}
              href={url}
              target="_blank"
              rel="noopener noreferrer"
            >
              Visit Link
            </Button>
          </Stack>
        </Box>

        <Box
          bgcolor="secondary.main"
          color="#ffffff"
          p={theme.spacing(1, 2, 2, 2)}
          borderRadius={theme.spacing(0, 0, 2, 2)}
        >
          <Typography textOverflow="ellipsis">{description}</Typography>
        </Box>
      </GenericModal>
      {actions}
    </Card>
  );
}
