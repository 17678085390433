import { FoldersPage } from '@app/components/FolderView';

export function BusinessLibraryFolders() {
  return (
    <FoldersPage
      title="Business Library"
      linkPrefix="/business-library"
      folderType="BUSINESS_LIBRARY"
      queryKey="business-library"
    />
  );
}
