import { useAuth } from '@app/hooks/useAuth';
import { useProfile } from '@app/hooks/useProfile';
import { Logout } from '@mui/icons-material';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface Props {
  anchorEl: HTMLElement | null | undefined;
  open: boolean;
  handleClose: () => void;
}

export function AvatarMenu(props: Props) {
  const { anchorEl, open, handleClose } = props;
  const { data } = useProfile();
  const navigate = useNavigate();
  const gotoProfile = () => navigate('/profile');

  const { logout } = useAuth();
  const logoutClick = () => {
    logout();
    navigate('/login');
  };

  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          borderRadius: 5,
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
        },
      }}
      MenuListProps={{
        sx: {
          padding: 0,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem
        onClick={gotoProfile}
        sx={{
          color: '#2d3145',
          borderRadius: 3,
          padding: 2,
          paddingLeft: 5,
          '&:hover': {
            bgcolor: '#2d3145',
            color: 'white',
          },
        }}
      >
        <Stack alignItems="end">
          <Typography>
            {data ? `${data.firstName} ${data.lastName}` : 'Loading...'}
          </Typography>
          <Typography>{data?.email}</Typography>
        </Stack>
      </MenuItem>
      <MenuItem
        onClick={logoutClick}
        sx={{
          color: '#2d3145',
          borderRadius: 3,
          padding: 2,
          paddingLeft: 5,
          '&:hover': {
            bgcolor: '#2d3145',
            color: 'white',
          },
        }}
      >
        <Stack direction="row" spacing={2} width="100%" justifyContent="right">
          <Logout />
          <Typography>Logout</Typography>
        </Stack>
      </MenuItem>
    </Menu>
  );
}
