import { Box, ButtonBase, Card, CardHeader } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

const colors = [
  '#922626',
  '#B53535',
  '#BC4444',
  '#DA752E',
  '#DC8B52',
  '#FFA31F',
];

interface Props {
  index: number;
  id: string;
  title: string;
  image?: string;
  actions?: React.ReactNode;
  linkPrefix: string;
}

export function ResourceFolderCard({
  title,
  image,
  id,
  index,
  linkPrefix,
  actions,
}: Props) {
  return (
    <Card elevation={5}>
      <NavLink
        to={`${linkPrefix}/${title}/${id}`}
        style={{ textDecoration: 'none' }}
      >
        <ButtonBase sx={{ width: 1, display: 'block' }}>
          <Box>
            <CardHeader
              title={title}
              titleTypographyProps={{
                variant: 'h6',
                fontWeight: 'bold',
                color: '#fff',
                textAlign: 'left',
              }}
              sx={{ pt: 2, pb: 1, bgcolor: colors[index % 6] }}
            />

            <Box p={1}>
              <Box
                sx={{
                  width: 1,
                  aspectRatio: '2',
                  borderRadius: 4,
                  overflow: 'hidden',
                  background: `${colors[index % 6]}22`,
                }}
              >
                {image && (
                  <Box
                    component="img"
                    src={image}
                    alt={title}
                    sx={{
                      width: 1,
                      aspectRatio: '2',
                      objectFit: 'cover',
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </ButtonBase>
      </NavLink>
      {actions}
    </Card>
  );
}
