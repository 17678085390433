import { Provider } from 'react-redux';
import { store } from '@app/store/index';

import { QueryClient, QueryClientProvider } from 'react-query';

import { ThemeProvider } from '@mui/material';
import { theme } from '@theme/theme';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@theme/override-tostify.css';

import { AppRoutes } from '@app/AppRoutes';

const queryClient = new QueryClient();

export function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <ToastContainer position="bottom-center" />
          <AppRoutes />
        </ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
}
