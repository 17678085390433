import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface BtnProps extends ButtonProps {
  isLoading?: boolean;
}

export function Btn({
  isLoading,
  endIcon,
  disabled,
  color,
  ...rest
}: BtnProps) {
  const btnDisabled = isLoading || disabled;
  const btnEndIcon = isLoading ? (
    <CircularProgress size={12} color={color} />
  ) : (
    endIcon
  );
  return (
    <Button
      color={color}
      endIcon={btnEndIcon}
      disabled={btnDisabled}
      {...rest}
    />
  );
}
