import { GenericModal, useModal } from '@app/components/GenericModal';
import { generateYoutubeVideoEmbedUrl } from '@app/util/helpers';
import { PlayArrow } from '@mui/icons-material';
import { Box, Button, Card, CardHeader, Typography } from '@mui/material';
import { theme } from '@theme/theme';
import React from 'react';

interface Props {
  title: string;
  image?: string;
  url: string;
  actions?: React.ReactNode;
  location?: React.ReactNode;
}

export function VideoResourceCard({
  title,
  url,
  image,
  actions,
  location,
}: Props) {
  const { isOpen, closeModal, openModal } = useModal();

  const youtubeEmbedUrl = generateYoutubeVideoEmbedUrl(url);

  return (
    <Card
      elevation={5}
      sx={{
        height: 1,
      }}
    >
      <CardHeader
        title={title}
        titleTypographyProps={{
          variant: 'h6',
          fontWeight: 'bold',
          color: '#fff',
          textAlign: 'left',
        }}
        sx={{ pt: 2, pb: 1, bgcolor: '#707070' }}
      />
      <Box p={1}>
        <Box
          sx={{
            position: 'relative',
            width: 1,
            aspectRatio: '6/5',
            borderRadius: 4,
            overflow: 'hidden',
            background: 'rgba(126, 0, 0, 0.1)',
          }}
        >
          {image ? (
            <Box
              component="img"
              src={image}
              alt={title}
              sx={{
                width: 1,
                aspectRatio: '6/5',
                objectFit: 'cover',
              }}
            />
          ) : // eslint-disable-next-line unicorn/no-nested-ternary
          youtubeEmbedUrl ? (
            <iframe
              style={{ pointerEvents: 'none' }}
              width="100%"
              height="100%"
              src={youtubeEmbedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <Box bgcolor="#ffaaaa" py={4}>
              <Typography color="error" align="center">
                Invalid URL
              </Typography>
            </Box>
          )}
          <Button
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
              background: '#fca718!important',
              color: '#fff',
              borderRadius: '50%',
            }}
            onClick={openModal}
          >
            <PlayArrow sx={{ fontSize: '4rem' }} />
          </Button>
        </Box>
      </Box>

      <GenericModal
        isOpen={isOpen}
        onClose={closeModal}
        width={1200}
        headerColor="transparent"
        closeIconBgColor={theme.palette.error.main}
        closeIconColor="#fff"
        title={location}
        useOnlyCloseButton
      >
        <Box height={{ xs: 400, md: 600 }} width={1} mt={-2}>
          {youtubeEmbedUrl && (
            <iframe
              width="100%"
              height="100%"
              src={youtubeEmbedUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          )}
        </Box>
      </GenericModal>
      {actions}
    </Card>
  );
}
