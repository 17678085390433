import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from '@app/App';

import './index.css';
import { reportWebVitals } from './reportWebVitals';

const container = document.querySelector('#root');
if (container) {
  const root = createRoot(container);
  root.render(
    <StrictMode>
      <App />
    </StrictMode>,
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
}
