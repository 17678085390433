/* eslint-disable unicorn/no-null */
import { KeyboardArrowDown } from '@mui/icons-material';
import { Button, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';

const options = [6, 12, 24];

interface Props {
  onChange: (perPageCount: number) => void;
  count: number;
}

export function PerPageCount({ onChange, count }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelect = (value: number) => () => {
    handleClose();
    onChange(value);
  };

  return (
    <>
      <Button
        id="items-per-page"
        aria-controls={open ? 'items per page' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        size="small"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDown />}
      >
        {count} per page
      </Button>
      <Menu
        id="items per page"
        MenuListProps={{
          'aria-labelledby': 'items',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {options.map(option => (
          <MenuItem key={option} onClick={onSelect(option)}>
            {option} per page
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
